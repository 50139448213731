import type { I18nOptions } from 'vue-i18n'

export const I18N_LOCALES = ['fr']
export const I18N_DEFAULT_LOCALE = 'fr'
export const I18N_DEFAULT_TIMEZONE = 'Europe/Paris'

// defineI18nConfig() does not work, therefore we need to type the config object

const dateFormatOptions = {
    // 28/01/2021
    'short': {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // 20:15
    'hour': {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // 28/01/2021 20:15
    'short-hour': {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // 28/01
    'short-date-only': {
        day: 'numeric',
        month: 'numeric',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // monday
    'weekday': {
        weekday: 'long',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // 28
    'date': {
        day: 'numeric',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // mon. 28 jan. 2021
    'long-date': {
        day: 'numeric',
        weekday: 'short',
        month: 'short',
        year: 'numeric',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // mon. 28 jan.
    'long-date-only': {
        day: 'numeric',
        weekday: 'short',
        month: 'short',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
    // mon. 28 jan. 20:15
    'long-date-hour-only': {
        day: 'numeric',
        weekday: 'short',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: I18N_DEFAULT_TIMEZONE,
    },

    // mon. 28 jan. 2021 20:15
    'long-date-hour': {
        day: 'numeric',
        weekday: 'short',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: I18N_DEFAULT_TIMEZONE,
    },
}

export type I18nDateFormatKeys = keyof typeof dateFormatOptions

export default {
    datetimeFormats: I18N_LOCALES.reduce(
        (acc, cur) => ({
            ...acc,
            [cur]: dateFormatOptions,
        }),
        {},
    ),
} satisfies I18nOptions
